"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.publishAsync = void 0;
const Pack_1 = require("../Pack");
const Context_1 = require("../Context");
const _ = __importStar(require("lodash"));
const PUBLISH_ASYNC = 'PUBLISH_ASYNC';
function publishAsync(prev, f) {
    return {
        type: PUBLISH_ASYNC,
        state: prefixPath => prev.state(prefixPath)
            .map(pack => _.filter(pack, v => !v.noEffect))
            .flatTap(pack => Context_1.Context.getPublish().map(publish => {
            (0, Pack_1.mapValuePack)(en => f(en)(publish))(pack);
        }))
            .map(() => (0, Pack_1.emptyPack)()),
        keys: Context_1.Context.emptyKeys(),
        value: Context_1.Context.emptyValue()
    };
}
exports.publishAsync = publishAsync;
