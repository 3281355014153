"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.map = void 0;
const Pack_1 = require("../Pack");
const MAPPED = 'MAPPED';
function map(prev, f) {
    return {
        type: MAPPED,
        keys: prev.keys,
        state: prefixPath => prev.state(prefixPath).map((0, Pack_1.mapValuePack)(f)),
        value: prefixPath => keys => prev.value(prefixPath)(keys).map((0, Pack_1.mapValuePack)(f))
    };
}
exports.map = map;
