import { IonButton, IonIcon } from '@ionic/react';
import { logoGoogle } from 'ionicons/icons';
import React from 'react';


const googleAuthUrl = `https://matrix.ulolo.app/_matrix/client/v3/login/sso/redirect?redirectUrl=${window.location.origin}/oauth/google?hs=martix.ulolo.app`

const GoogleAuthButton: React.FC = () => {

  return (

    <IonButton color="primary" className='w-full' fill="outline" href={googleAuthUrl}>
      <IonIcon slot="start" icon={logoGoogle}></IonIcon>
      Login with Google
    </IonButton >
  );
};

export default GoogleAuthButton;
