import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CheckboxChangeEventDetail, IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonTitle, IonToggle, IonToolbar, ToggleChangeEventDetail, ToggleCustomEvent, useIonActionSheet } from '@ionic/react';
import { useQuListener } from 'qume-react';
import { CREATE_TODO, CreateToDo, ToDo, TODO_ACTIVATED, TODO_COMPLETED } from 'proto/dist/todo/types';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import * as _ from "lodash"
import { qustore, ROOM_ID_SELECTED, SEND_MESSAGE } from '../features/todo-matrix/service/MatrixEventSource';
import { mainStore } from '../features/todo-matrix/service';
import { addCircle, addCircleOutline, arrowUpCircle, chevronBackOutline, menuOutline } from 'ionicons/icons';

// import { mainQuery } from '../service';
// import { QumeProvider } from 'qume-react';


const Chat: React.FC = () => {

  const page = useRef(undefined);
  const { roomId } = useParams<{ roomId: string }>()

  if (!roomId) return (
    <IonPage> Loading </IonPage>
  )

  const navigate = useNavigate();

  useEffect(() => {
    console.log('extract for roomId', roomId)
    qustore.publish({ type: ROOM_ID_SELECTED, roomId: roomId })
  }, [roomId])

  const [text, setText] = useState('');


  function sendMessage() {
    if (text) {
      publish({ type: CREATE_TODO, title: text })
    }
  }
  function sendTodo() {

    if (text && roomId) {
      qustore.publish({ type: SEND_MESSAGE, roomId: roomId, text })
    }
  }

  const modalHeight = 100
  const startBreakpoint = modalHeight / window.innerHeight
  const openBreakpoint = 0.75
  const modal = useRef<HTMLIonModalElement>(null);
  const [presentingElement, setPresentingElement] = useState<HTMLElement | undefined>(undefined);
  const [breakpoint, setBreakpoint] = useState(startBreakpoint)

  function switchModal() {
    if (breakpoint == startBreakpoint) {
      modal.current?.setCurrentBreakpoint(openBreakpoint)
    } else {
      modal.current?.setCurrentBreakpoint(startBreakpoint)
    }
  }

  const [showAll, setShowAll] = useState(false)

  function onCheckBoxChange(id: string): (ev: CustomEvent<CheckboxChangeEventDetail>) => void {
    return ev => {
      if (ev.detail.checked) publish({ type: TODO_COMPLETED, id })
      else publish({ type: TODO_ACTIVATED, id })
    }
  }

  const room = useQuListener(qustore.listener.roomSelected)
  const roomName = room['']?.room.name

  const all = useQuListener(mainStore.listener.all)
  const active = useQuListener(mainStore.listener.active)
  const publish = useCallback((ev: any) => mainStore.publish(ev), [])

  return (
    <IonPage ref={page}>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => navigate(-1)}>
              <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>{roomName}</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} >

              <IonIcon slot="icon-only" icon={menuOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <div>


        <div className='h-full relative'>

          <IonContent >

          </IonContent>


          <IonModal
            ref={modal}
            isOpen={true}
            initialBreakpoint={startBreakpoint}
            canDismiss={false}
            breakpoints={[startBreakpoint, openBreakpoint, 1]}
            backdropBreakpoint={1}
            onIonBreakpointDidChange={ev => setBreakpoint(ev.detail.breakpoint)}
          >
            <IonContent >

              <div>
                <IonList inset={true} >
                  <IonItem color='light'>
                    {
                      _.isEmpty(text)
                        ? <IonIcon color='primary' icon={menuOutline} slot="start" size='large' onClick={switchModal} />
                        : <IonIcon color='primary' icon={addCircleOutline} slot="start" size='large' onClick={sendTodo} />
                    }
                    <input type='text' placeholder='Message' className='w-full focus:outline-none bg-opacity-0 bg-white'
                      value={text} onChange={e => setText(e.target.value)} />
                    <IonIcon color='primary' icon={arrowUpCircle} slot="end" size='large' onClick={sendMessage} />
                  </IonItem>
                </IonList>

                <IonList > {
                  _.isEmpty(showAll ? all : active) ? (
                    <IonItem> No items </IonItem>
                  ) : _.map(showAll ? all : active, (todo) => {
                    return (
                      <IonItem className='pl-2' key={todo.id}>
                        <IonCheckbox
                          labelPlacement="start"
                          checked={!todo.active}
                          onIonChange={onCheckBoxChange(todo.id)}>
                          {todo.title}
                        </IonCheckbox>
                      </IonItem>
                    )
                  })
                } </IonList>
              </div>

            </IonContent>
          </IonModal>

        </div>

      </div>
    </IonPage >
  );
};

export default Chat;
