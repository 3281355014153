"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.errorLog = exports.infoLog = void 0;
const infoLog = (...params) => {
    console.log(...params);
};
exports.infoLog = infoLog;
const errorLog = (...params) => {
    console.error(...params);
};
exports.errorLog = errorLog;
