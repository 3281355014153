import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonItem, IonLabel } from '@ionic/react';
import React from 'react';



const UserAvatar: React.FC<{
  name: string,
  size?: 'small' | 'medium' | 'large'
}> = ({ name, size = 'medium' }) => {
  const sizeClasses = {
    small: 'w-8 h-8 text-xs',
    medium: 'w-12 h-12 text-2xl',
    large: 'w-16 h-16 text-base',
  };

  const avatarSize = sizeClasses[size] || sizeClasses.medium;

  const getBackgroundColor = (name: string) => {
    const firstLetter = name.charAt(0).toLowerCase();
    const charCode = firstLetter.charCodeAt(0);
    const hue = (charCode * 137) % 360;
    return `hsl(${hue}, 50%, 60%)`;
  };

  return (
    <div className="rounded-full flex items-center justify-center font-bold text-white w-12 h-12 text-2xl"
      style={{ backgroundColor: getBackgroundColor(name) }} >
      {name.charAt(0).toUpperCase()}
    </div>
  );
};

export default UserAvatar;
