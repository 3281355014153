import { CREATE_TODO, TODO, ToDoFact, TODO_ACTIVATED, TODO_COMPLETED, TODO_CREATED } from 'proto/dist/todo/types'
import React, { useContext, useState, createContext, useEffect, useCallback, useRef, useMemo } from 'react'
import { Context, EventSource, HasType, InMemoryStorage, Pack, PackSubscriber, QueryObj, QueryRecord, QueryStore, QueryStoreListener, QueryTail, Reader, State, Storage } from 'qume';
import * as _ from "lodash"
import { WebrtcProvider } from 'y-webrtc'

import { scope } from 'qume'
import { fullPack } from 'qume/dist/internal/Pack';


import { RoomEvent, LoginResponse, createClient, EventType, MatrixClient, MsgType, MatrixEvent } from 'matrix-js-sdk';
import { ACCESS_TOKEN_SET, DEVICE_ID_SET, MatrixEventSource, qustore, USER_ID_SET } from './MatrixEventSource';



export const { query, store } = scope<ToDoFact>()

export const mainStore = store({
  created: query(CREATE_TODO).withUniqueId().publish(TODO_CREATED),
  all: query({
    id: query(TODO_CREATED).byId().map(v => v.id),
    title: query(TODO_CREATED).byId().map(v => v.title),
    active: query(
      query(TODO_CREATED).byId().as(true),
      query(TODO_ACTIVATED).byId().as(true),
      query(TODO_COMPLETED).byId().as(false),
    )
  }).publish(TODO),
  active: query(TODO).byId().filter(todo => todo.active),
})
  .withSource(new MatrixEventSource())
  .run()


const userId = localStorage.getItem('userId') as string
const deviceId = localStorage.getItem('deviceId') as string
const accessToken = localStorage.getItem('accessToken') as string

if (userId) {
  console.log('read', userId, deviceId, accessToken)
  qustore.publish({ type: USER_ID_SET, userId })
  qustore.publish({ type: DEVICE_ID_SET, deviceId })
  qustore.publish({ type: ACCESS_TOKEN_SET, accessToken })
}





