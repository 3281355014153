import { CREATE_TODO, TODO, ToDoFact, TODO_ACTIVATED, TODO_COMPLETED, TODO_CREATED } from 'proto/dist/todo/types'
import React, { useContext, useState, createContext, useEffect, useCallback, useRef, useMemo } from 'react'
import { Context, EventSource, HasType, InMemoryStorage, Pack, PackSubscriber, QueryObj, QueryRecord, QueryStore, QueryStoreListener, QueryTail, Reader, State, Storage } from 'qume';
import * as _ from "lodash"
import * as Y from 'yjs'
import { WebrtcProvider } from 'y-webrtc'

import { scope } from 'qume'
import { fullPack, entry } from 'qume/dist/internal/Pack';


import { RoomEvent, LoginResponse, createClient, EventType, MatrixClient, MsgType, MatrixEvent, IEventWithRoomId, RoomStateEvent, Visibility, ClientEvent, SyncState, Room, IContent } from 'matrix-js-sdk';



const homeserver = "https://matrix.ulolo.app/"
const userId = "@maxkorolev:ulolo.app"
// const accessToken = "syt_bWF4a29yb2xldg_PXEVFrIpEAOnXQNsIGWy_1gYdGy"
const deviceId = "IHTLDCEBQY"
const roomId = "!RzZRfLxPAOgeTgpseG:ulolo.app"



export const HOMESERVER_SET = 'HOMESERVER_SET'
export const USER_ID_SET = 'USER_ID_SET'
export const USER_PASSWORD_SET = 'USER_PASSWORD_SET'
export const ACCESS_TOKEN_SET = 'ACCESS_TOKEN_SET'
export const LOGIN_TOKEN_SET = 'LOGIN_TOKEN_SET'
export const DEVICE_ID_SET = 'DEVICE_ID_SET'
export const CLIENT_CREATED = 'CLIENT_CREATED'
export const CLIENT_STARTED = 'CLIENT_STARTED'
export const CLIENT_INIT = 'CLIENT_INIT'
export const USER_PROFILE = 'USER_PROVILE'

export const TIMELINE_EVENT = 'TIMELINE_EVENT'
export const ROOM_MEMBER_EVENT = 'ROOM_MEMBER_EVENT'

export const ROOMS_EXTRACTED = 'ROOMS_EXTRACTED'
export const CREATE_ROOM = 'CREATE_ROOM'
export const ROOM_ID_SELECTED = 'ROOM_ID_SELECTED'
export const ROOM_SELECTED = 'ROOM_SELECTED'

export const EVENTS_SYNCED = 'EVENTS_SYNCED'

export const SEND_MESSAGE = 'SEND_MESSAGE'


export type BaseUrlSet = { type: typeof HOMESERVER_SET, homeserver: string }
export type UserIdSet = { type: typeof USER_ID_SET, userId: string }
export type UserPasswordSet = { type: typeof USER_PASSWORD_SET, password: string }
export type LoginTokenSet = { type: typeof LOGIN_TOKEN_SET, loginToken: string }
export type AccessTokenSet = { type: typeof ACCESS_TOKEN_SET, accessToken: string }
export type DeviceIdSet = { type: typeof DEVICE_ID_SET, deviceId: string }
export type ClientCreated = { type: typeof CLIENT_CREATED, client: MatrixClient }
export type ClientStarted = { type: typeof CLIENT_STARTED, client: MatrixClient }
export type ClientInit = { type: typeof CLIENT_INIT, client: MatrixClient }
export type UserProfile = { type: typeof USER_PROFILE, displayname: string, avatarUrl: string }

export type TimelineEvent = { type: typeof TIMELINE_EVENT, event: MatrixEvent }
export type RoomMemberEvent = { type: typeof ROOM_MEMBER_EVENT, client: MatrixClient, event: MatrixEvent }

export type RoomsExtracted = { type: typeof ROOMS_EXTRACTED, rooms: Room[] }
export type CreateRoom = { type: typeof CREATE_ROOM, title: string }
export type RoomIdSelected = { type: typeof ROOM_ID_SELECTED, roomId: string }
export type RoomSelected = { type: typeof ROOM_SELECTED, room: Room }

export type EventsSynced = { type: typeof EVENTS_SYNCED, events: IEventWithRoomId[] }
export type SendMessage = { type: typeof SEND_MESSAGE, roomId: string, text: string }

export type MatrixClientFact =
  | BaseUrlSet
  | UserIdSet
  | UserPasswordSet
  | AccessTokenSet
  | LoginTokenSet
  | DeviceIdSet
  | ClientCreated
  | ClientStarted
  | ClientInit
  | UserProfile
  | TimelineEvent
  | RoomMemberEvent
  | RoomsExtracted
  | CreateRoom
  | RoomIdSelected
  | RoomSelected
  | EventsSynced
  | SendMessage


const eventType = 'app.ulolo.todo'

const { query, store } = scope<MatrixClientFact>()

// const a = {
//   access_token: "syt_bWF4_moNawhryousDOgBmFKsP_2llSBq",
//   device_id: "OZGJHYOIYV",
//   home_server: "ulolo.app",
//   user_id: "@max:ulolo.app",
// }

export const qustore = store({

  // clientLoggedIn: query({
  //   homeserver: query(HOMESERVER_SET).focus.homeserver.byKey(() => ''),
  //   user: query(USER_ID_SET).focus.userId.byKey(() => ''),
  //   password: query(USER_PASSWORD_SET).focus.password.byKey(() => '')
  // }).evalMap(async ({ user, password, homeserver }) => {
  //   const client = createClient({ baseUrl: homeserver })
  //   const login = await client.login('m.login.password', { user, password })
  //   const initSync = await client.roomInitialSync(roomId, 3000)
  //   return { client, messages: initSync.messages?.chunk, login }
  // }).publish(CLIENT_LOGGED_IN),
  //
  clientByAccessToken: query({
    baseUrl: query(HOMESERVER_SET).focus.homeserver.byKey(() => ''),
    userId: query(USER_ID_SET).focus.userId.byKey(() => ''),
    accessToken: query(ACCESS_TOKEN_SET).focus.accessToken.byKey(() => ''),
    deviceId: query(DEVICE_ID_SET).focus.deviceId.byKey(() => ''),
  }).evalMap(async opts => {

    console.log('clientByAccessToken', JSON.stringify(opts, null, 2))
    const client = createClient(opts);
    return { client }
  }).publish(CLIENT_CREATED),

  clientByLoginToken: query({
    homeserver: query(HOMESERVER_SET).focus.homeserver.byKey(() => ''),
    loginToken: query(LOGIN_TOKEN_SET).focus.loginToken.byKey(() => ''),
  }).once().evalMap(async ({ homeserver, loginToken }) => {
    const client = createClient({ baseUrl: homeserver })
    console.log('loginToken', loginToken)
    const login = await client.login('m.login.token', { token: loginToken })
    console.log('sssoooooooooooooo', login)

    localStorage.setItem('userId', login.user_id)
    localStorage.setItem('deviceId', login.device_id)
    localStorage.setItem('accessToken', login.access_token)

    return { client }
  }).publish(CLIENT_CREATED),

  clientCreated: query(CLIENT_CREATED).byKey(() => ''),

  timelineEvents: query(CLIENT_CREATED).focus.client.once()
    .publishAsync(client => pub => {

      console.log('==== RoomEvent.Timeline subscribed')
      client.on(RoomEvent.Timeline, event =>
        pub({ type: TIMELINE_EVENT, event })
      )
    }),

  events: query(TIMELINE_EVENT).byKey(() => '').filter(event =>
    event.event.getType() == eventType
  ),


  clientStarted: query(CLIENT_CREATED).byKey(() => '')
    .evalMap(async ({ client }) => {
      await client.startClient()
      return { client }
    })
    .publish(CLIENT_STARTED),

  clientInit: query(CLIENT_CREATED).byKey(() => '').once().evalMap(async ({ client }) => {

    return new Promise(resolve => {
      const cb = function (state: SyncState) {
        if (state === SyncState.Prepared) {
          console.log("yuuuuuuuuuuuuu ", state, client.getRooms());

          client.off(ClientEvent.Sync, cb)
          resolve({ client })
        } else {
          console.log('syncstate', state);
        }
      }

      console.log('==== ClientEvent.Sync subscribed')
      client.on(ClientEvent.Sync, cb)
    })
  }).publish(CLIENT_INIT),


  clientProfile: query({
    client: query(CLIENT_CREATED).focus.client.byKey(() => ''),
    userId: query(USER_ID_SET).focus.userId.byKey(() => ''),
  })
    .evalMap(({ client, userId }) => client.getProfileInfo(userId))
    .map(v => ({ displayname: v.displayname, avatarUrl: v.avatar_url }))
    .byKey(() => '')
    .publish(USER_PROFILE),

  roomMemberEvents: query(CLIENT_CREATED).once().focus.client
    .publishAsync(client => pub => {

      console.log('==== RoomStateEvent.Members subscribed')
      client.on(RoomStateEvent.Members, event => {
        console.log('RoomStateEvent.Members', client.getRooms(), event)

        pub({ type: ROOM_MEMBER_EVENT, client, event })
      })
    }),

  roomsExtracted: query(
    query(CLIENT_INIT).focus.client,
    query(ROOM_MEMBER_EVENT).focus.client
  )
    .map(client => ({ rooms: client.getRooms() }))
    .byKey(() => '')
    .publish(ROOMS_EXTRACTED),

  rooms: query(ROOMS_EXTRACTED).byKey(() => ''),

  roomCreated: query({
    client: query(CLIENT_STARTED).focus.client.byKey(() => ''),
    room: query(CREATE_ROOM).byKey(() => '')
  }).evalMap(({ client, room }) => {

    console.log('Create room', room)
    return client.createRoom({
      visibility: Visibility.Private,
      name: room.title,
      is_direct: false,
    })
  }),

  roomSelected: query({
    extracted: query(ROOMS_EXTRACTED).focus.rooms.byKey(() => ''),
    selected: query(ROOM_ID_SELECTED).focus.roomId.byKey(() => '')
  })
    .map(({ extracted, selected }) => _.find(extracted, r => r.roomId == selected))
    .filter(r => r != undefined)
    .map(room => ({ room }))
    .publish(ROOM_SELECTED),

  roomEventsSynced: query({
    client: query(CLIENT_STARTED).focus.client.byKey(() => ''),
    roomId: query(ROOM_ID_SELECTED).focus.roomId.byKey(() => '')
  })
    .evalMap(async ({ client, roomId }) => {

      const events = await client.roomInitialSync(roomId, 3000)
      console.log('Room Initial sync', roomId, events)
      return { events: events.messages?.chunk }
    })
    .publish(EVENTS_SYNCED),



}).run()

export class MatrixEventSource<S> implements EventSource<S> {

  private _client: MatrixClient | undefined;
  private _room: Room | undefined;
  private _seq = 0
  private _subscribers: PackSubscriber<any, S>[] = [];

  private subscribeOnce = false

  public setClient(value: MatrixClient) {
    this._client = value;
  }
  public setRoom(value: Room) {
    this._room = value;
  }

  constructor() {

    qustore.publish({ type: HOMESERVER_SET, homeserver })
    // qustore.publish({ type: USER_ID_SET, userId })
    // qustore.publish({ type: ACCESS_TOKEN_SET, accessToken })
    // qustore.publish({ type: DEVICE_ID_SET, deviceId })

    qustore.listener.events.listen(event => {
      if (!event['']?.event) return

      this.process(event[''].event.getContent() as S)
    })

    qustore.listener.clientCreated.listen(init => {
      // if (!init['']?.messages) return
      if (!init['']?.client) return
      if (this.subscribeOnce) return

      this.subscribeOnce = true


    })
    qustore.listener.clientCreated.listen(created => {
      if (created[''] && created[''].client) {
        this.setClient(created[''].client)
      }
    })
    qustore.listener.roomSelected.listen(room => {
      if (room[''] && room[''].room) {
        this.setRoom(room[''].room)
      }
    })
    qustore.listener.roomEventsSynced.listen(roomEvents => {
      const events = roomEvents['']?.events.filter(e => e.type == eventType)
      if (_.isEmpty(events)) return

      this.rehydrate(events.map(v => v.content as S))
    })
  }

  publish(event: S) {
    try {
      if (!this._client) return
      if (!this._room?.roomId) return

      this._client.sendEvent<any>(this._room.roomId, eventType, event)
    } catch (err) {
      console.error(err)

    }
  }

  subscribe(subs: PackSubscriber<any, S>): void {
    this._subscribers = [...this._subscribers, ...[subs]]
  }

  private process(content: S) {

    this._seq = this._seq + 1
    const pack = fullPack('', content, this._seq, false, false)
    _.forEach(this._subscribers, subs => subs(pack))
  }

  private rehydrate(events: S[]) {
    this._seq = this._seq + events.length
    const pack = _.map(events, ev => entry('', ev, this._seq, false, true))
    console.log('rehydrating', pack)
    _.forEach(this._subscribers, subs => subs(pack))
  }

}






