"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.byKey = void 0;
const Pack_1 = require("../Pack");
const Context_1 = require("../Context");
const BY_KEY = 'BY_KEY';
function byKey(prev, f) {
    return {
        type: BY_KEY,
        state: prefixPath => prev.state(prefixPath).map((0, Pack_1.mapKeyPack)(f))
            .flatTap(Context_1.Context.writeByKeys(prefixPath)),
        keys: Context_1.Context.allKeys(),
        value: Context_1.Context.valueByKeys()
    };
}
exports.byKey = byKey;
