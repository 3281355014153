import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonItem, IonLabel } from '@ionic/react';
import React from 'react';


const ProgressBar: React.FC = () => {

  return (
    <div className="relative">
      <svg className="size-full" width="10" height="10" viewBox="0 0 10 10">
        <circle className="stroke-current text-gray-200"
          cx="5" cy="5" r="4" fill="none" strokeWidth="1"></circle>
        <g className="origin-center -rotate-90 transform">
          <circle className="stroke-current text-green-500"
            cx="5" cy="5" r="4" fill="none" strokeWidth="1" strokeDasharray="100" strokeDashoffset="80"></circle>
        </g>
      </svg>
      <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
        <span className="text-center text-2xl font-bold text-white">72%</span>
      </div>
    </div>
  );
};

export default ProgressBar;
