"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pathKey = exports.FOLD = exports.TRANSFORM = exports.EXTRACT = void 0;
exports.EXTRACT = 'EXTRACT';
exports.TRANSFORM = 'TRANSFORM';
exports.FOLD = 'FOLD';
function pathKey(path, key) {
    if (key === '')
        return path;
    else if (path === '')
        return key + '';
    else
        return path + '.' + key;
}
exports.pathKey = pathKey;
