"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.optional = void 0;
const Pack_1 = require("../Pack");
const _ = __importStar(require("lodash"));
const OPTIONAL = 'OPTIONAL';
function optional(prev) {
    return {
        type: OPTIONAL,
        state: prefixPath => prev.state(prefixPath).map((0, Pack_1.flatMapPack)((value, key, seqId, deleted, noEffect) => deleted ? (0, Pack_1.fullPack)(key, undefined, seqId, false, noEffect) : (0, Pack_1.fullPack)(key, value, seqId, deleted, noEffect))),
        keys: prev.keys,
        value: prefixPath => keys => prev.value(prefixPath)(keys)
            .map(pack => _.reduce(keys, (acc, key) => {
            const entryPack = _.find(pack, en => en.key == key && !en.deleted)
                || (0, Pack_1.entry)(key, undefined, 0, false);
            return (0, Pack_1.concatPack)(acc, [entryPack]);
        }, []))
    };
}
exports.optional = optional;
