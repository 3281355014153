"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reduce = void 0;
const Context_1 = require("../Context");
const REDUCE = 'REDUCE';
function reduce(prev, f) {
    return {
        type: REDUCE,
        state: prefixPath => prev.state(prefixPath).flatMap(Context_1.Context.modifyByKeys(prefixPath, f)),
        keys: Context_1.Context.allKeys(),
        value: Context_1.Context.valueByKeys()
    };
}
exports.reduce = reduce;
