"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TODO_LIST = exports.TODO = exports.TODO_COMPLETED = exports.TODO_ACTIVATED = exports.TODO_CREATED = exports.RESPAWN_TODO = exports.COMPLETE_TODO = exports.CREATE_TODO = void 0;
exports.CREATE_TODO = 'CREATE_TODO';
exports.COMPLETE_TODO = 'COMPLETE_TODO';
exports.RESPAWN_TODO = 'RESPAWN_TODO';
exports.TODO_CREATED = 'TODO_CREATED';
exports.TODO_ACTIVATED = 'TODO_ACTIVATED';
exports.TODO_COMPLETED = 'TODO_COMPLETED';
exports.TODO = 'TODO';
exports.TODO_LIST = 'TODO_LIST';
