"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reader = void 0;
const _ = __importStar(require("lodash"));
class Reader {
    constructor(run) {
        this.run = run;
    }
    static apply(run) { return new Reader(run); }
    static func(run) { return Reader.apply(s => new Promise(r => r(run(s)))); }
    static pure(a) { return Reader.func(() => a); }
    static ask() { return Reader.func((s) => s); }
    static inspect(f) { return Reader.func((s) => f(s)); }
    static void() { return Reader.func(() => undefined); }
    // extract(): (s: S) => A {
    //   return s => this.run(s)[1
    // }
    static traverseArr(arrA, f) {
        return _.reduce(arrA, (acc, a, k) => acc.flatMap(arrB => f(a, k).map(b => arrB.concat([b]))), Reader.pure([]));
    }
    static traverseObj(objA, f) {
        return this.traverseArr(_.toPairs(objA), ([k, v]) => f(v, k));
    }
    flatMap(f) {
        return Reader.apply((s) => __awaiter(this, void 0, void 0, function* () {
            const a = yield this.run(s);
            return yield f(a).run(s);
        }));
    }
    flatTap(f) {
        return this.flatMap(a => f(a).as(a));
    }
    map(f) {
        return this.flatMap(v => Reader.pure(f(v)));
    }
    as(b) {
        return this.flatMap(() => Reader.pure(b));
    }
    asF(st) {
        return this.flatMap(() => st);
    }
    inspect(f) {
        return this.flatMap(() => Reader.inspect(f));
    }
}
exports.Reader = Reader;
