"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryStore = exports.QueryStoreRunner = exports.LoopbackEventSource = void 0;
const _ = __importStar(require("lodash"));
const Context_1 = require("./Context");
const Reader_1 = require("./Reader");
const State_1 = require("./State");
const Pack_1 = require("./Pack");
class LoopbackEventSource {
    constructor() {
        this._seq = 0;
        this._subscribers = [];
    }
    publish(event) {
        this._seq = this._seq + 1;
        const pack = (0, Pack_1.fullPack)('', event, this._seq);
        _.forEach(this._subscribers, subs => subs(pack));
        return this._seq;
    }
    subscribe(subs) {
        this._subscribers = [...this._subscribers, ...[subs]];
    }
}
exports.LoopbackEventSource = LoopbackEventSource;
class QueryStoreRunner {
    constructor(query, source = new LoopbackEventSource(), storage = new Context_1.InMemoryStorage()) {
        this._query = query;
        this._source = source;
        this._storage = storage;
    }
    withSource(source) {
        return new QueryStoreRunner(this._query, source, this._storage);
    }
    withStorage(storage) {
        return new QueryStoreRunner(this._query, this._source, storage);
    }
    run() {
        return new QueryStore(this._query, this._source, this._storage);
    }
}
exports.QueryStoreRunner = QueryStoreRunner;
class StorageListener {
    constructor(qt, path, storage, handler) {
        this.qt = qt;
        this.path = path;
        this.storage = storage;
        this.handler = handler;
    }
    fire() {
        this.qt.recordR(this.path)
            .run(this.storage)
            .then(value => { this.handler(value); });
    }
}
class QueryStore {
    get storage() { return this._storage; }
    // public get context(): Context<S> { return new Context(this._storage) }
    get query() { return this._query; }
    get inprogress() { return this._current; }
    get snapshot() {
        return _.reduce(this._query, (acc, qt, key) => (Object.assign(Object.assign({}, acc), { [key]: () => qt.recordR(key).run(this._storage) })), {});
    }
    get listener() {
        const self = this;
        return _.reduce(this._query, (acc, qt, key) => (Object.assign(Object.assign({}, acc), { [key]: {
                listen(cb) { self.listen(key, cb); },
                unlisten(cb) { self.unlisten(cb); }
            } })), {});
    }
    listen(path, cb) {
        const qt = this._query[path];
        const listener = new StorageListener(qt, path, this._storage, cb);
        // if we trigger right away, all subs will receive null first
        // user needs to make sure listeners iniated before the first event is processed
        // but when it is impossible to initialize listener before - we have to fire
        listener.fire();
        this._storageListeners = [...this._storageListeners, ...[listener]];
    }
    unlisten(cb) {
        this._storage.unlisten(cb);
        this._storageListeners = _.filter(this._storageListeners, l => l.handler != cb);
    }
    constructor(query, eventSource, storage) {
        this._storageListeners = [];
        this.process = (pack) => {
            if (_.isEmpty(pack))
                return;
            this._current = this._current.then(() => State_1.State
                .traverseObj(this._query, (qu, path) => Context_1.Context.setInlet(pack).asF(qu.run(path)))
                .runS(new Context_1.Context(this._storage).withPublish(s => this.publish(s)))
                .then(ctx => _.forEach(ctx.outlet, entry => this.publish(entry.value)))
                .then(() => _.forEach(this._storageListeners, listener => listener.fire()))
                .then(() => undefined));
        };
        this._query = query;
        this._current = Promise.resolve(undefined);
        this._storage = storage;
        this._eventSource = eventSource;
        this._eventSource.subscribe(pack => this.process(pack));
        this._storage.listen(() => _.forEach(this._storageListeners, listener => listener.fire()));
    }
    publish(event) {
        return this._eventSource.publish(event);
    }
    readAll() {
        if (!this._query)
            return Promise.resolve({});
        else {
            return Reader_1.Reader.traverseObj(this._query, (qu, path) => qu.allR(path).map((v) => ({
                [path]: Object.fromEntries(v.entries())
            }))).map(values => _.reduce(values, (acc, v) => (Object.assign(Object.assign({}, acc), v)), {})).run(this._storage);
        }
    }
}
exports.QueryStore = QueryStore;
