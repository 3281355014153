"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Context = exports.InMemoryStorage = exports.isProcess = exports.isRead = void 0;
const base_1 = require("./base");
const State_1 = require("./State");
const _ = __importStar(require("lodash"));
const Pack_1 = require("./Pack");
const Reader_1 = require("./Reader");
function isRead(execution) { return execution.type == 'READ'; }
exports.isRead = isRead;
function isProcess(execution) { return execution.type == 'PROCESS'; }
exports.isProcess = isProcess;
class InMemoryStorage {
    constructor() {
        this._map = {};
    }
    listen(_cb) { }
    unlisten(_cb) { }
    readMeta() {
        return Promise.resolve(_.mapValues(this._map, v => v.seqId));
    }
    readValues() {
        return Promise.resolve(_.mapValues(this._map, v => v.value));
    }
    readValue(key) {
        return Promise.resolve(this._map[key]);
    }
    storeValue(key, value, seqId) {
        return new Promise(resolve => {
            this._map = Object.assign(Object.assign({}, this._map), { [key]: { value, seqId } });
            resolve();
        });
    }
    dropValue(key) {
        return new Promise(resolve => {
            this._map = _.omit(this._map, key);
            resolve();
        });
    }
}
exports.InMemoryStorage = InMemoryStorage;
class Context {
    constructor(storage = new InMemoryStorage(), inlet = [], outlet = [], publish = () => {
        throw new Error("Function is not implemented");
    }) {
        this.storage = storage;
        this.inlet = inlet;
        this.outlet = outlet;
        this.publish = publish;
    }
    // sepIdByType(type: string): Promise<number> {
    //   return this.storage.sepIdByType(type)
    // }
    withStorage(storage) {
        return new Context(storage, this.inlet, this.outlet, this.publish);
    }
    withInlet(pack) {
        return new Context(this.storage, pack, this.outlet, this.publish);
    }
    withInletEvent(inlet) {
        if (!_.isArray(inlet))
            inlet = [inlet];
        const pack = inlet.map(v => (0, Pack_1.entry)('', v, 0, false));
        return new Context(this.storage, pack, this.outlet, this.publish);
    }
    withOutlet(pack) {
        return new Context(this.storage, this.inlet, pack, this.publish);
    }
    withPublish(cb) {
        return new Context(this.storage, this.inlet, this.outlet, cb);
    }
    // readOffsets(): Promise<Record<Path, number>> {
    //   return this.storage.readOffsets()
    // }
    static make(storage) {
        return new Context(storage);
    }
    static empty() {
        return new Context(new InMemoryStorage());
    }
    static read(path, orEmpty) {
        return State_1.State.inspectF(s => s.storage.readValue(path).then(v => v || orEmpty));
    }
    static readOpt(path) {
        return State_1.State.inspectF(s => s.storage.readValue(path));
    }
    // static readOffsets<S>(): State<Context<S>, Record<Path, number>> {
    //   return State.inspectF(s => s.storage.readOffsets())
    // }
    // static storeOffsets<S>(v: Record<string, number>): State<Context<S>, void> {
    //   return State.inspectF(ctx => ctx.storage.readOffsets().then(ctxOffsets => {
    //
    //     const offsets = _.chain(_.keys(v).concat(_.keys(ctxOffsets))).uniq().reduce((acc, key) =>
    //       ({ ...acc, [key]: _.max([v[key] || 0, ctxOffsets[key] || 0]) }), {}
    //     ).value()
    //
    //     return ctx.storage.storeOffsets(offsets)
    //   }))
    // }
    static storeValue(path, v, seqId) {
        return State_1.State.inspectF(s => s.storage.storeValue(path, v, seqId));
    }
    static delete(path) {
        return State_1.State.inspectF(s => s.storage.dropValue(path));
    }
    static getInlet() {
        return State_1.State.inspect(st => st.inlet);
    }
    static setInlet(inlet) {
        return State_1.State.modify(st => st.withInlet(inlet));
    }
    static getOutlet() {
        return State_1.State.inspect(st => st.outlet);
    }
    static emptyOutlet() {
        return State_1.State.modify(st => st.withOutlet([]));
    }
    static setOutlet(outlet) {
        return State_1.State.modify(st => st.withOutlet(outlet));
    }
    static addOutlet(outlet) {
        return State_1.State.modify(ctx => ctx.withOutlet(ctx.outlet.concat(outlet)));
    }
    static getPublish() {
        return State_1.State.inspect(st => st.publish);
    }
    static writeByKeys(path) {
        return (0, Pack_1.foldPack)(State_1.State.void(), (acc, v, k, seqId, deleted) => {
            const key = (0, base_1.pathKey)(path, k);
            if (deleted)
                return acc.asF(Context.delete(key));
            else
                return acc.flatTap(() => Context.storeValue(key, v, seqId)).void();
        });
    }
    static modifyByKeys(path, f) {
        return pack => State_1.State.traverseArr(pack, entry => {
            const key = (0, base_1.pathKey)(path, entry.key);
            if (entry.deleted)
                return Context.delete(key).as((0, Pack_1.emptyPack)());
            else
                return Context
                    .read(key, { value: entry.value, seqId: entry.seqId })
                    .map(r => (Object.assign(Object.assign({}, r), f(r.value, entry.value))))
                    .flatTap(r => Context.storeValue(key, r.value, entry.seqId))
                    .map(v => (0, Pack_1.fullPack)(entry.key, v.value, v.seqId, entry.deleted, entry.noEffect));
        }).map(Pack_1.flattenPack);
    }
    static emptyValue() {
        return () => () => Reader_1.Reader.func(() => (0, Pack_1.emptyPack)());
    }
    static valueByKeys() {
        return path => keys => {
            const readF = _.reduce(keys, (acc, k) => acc.flatMap(pack => {
                return Context.readOpt((0, base_1.pathKey)(path, k))
                    .map(r => r ? (0, Pack_1.concatPack)(pack, (0, Pack_1.fullPack)(k, r.value, r.seqId)) : pack);
            }), State_1.State.pure((0, Pack_1.emptyPack)())).runA;
            return Reader_1.Reader.apply(readF);
        };
    }
    static emptyKeys() {
        return () => Reader_1.Reader.func(() => ['']);
    }
    static allKeys() {
        return path => {
            const regexp = new RegExp(`^${path}\.([0-9a-zA-Z]*)$`, 'g');
            const readF = State_1.State.inspectF((st) => st.storage.readValues()).map(values => _.chain(values)
                .map((_v, k) => Array.from(k.matchAll(regexp), p => p[1]))
                .map(k => (_.head(k) || ''))
                .uniq()
                .value()).runA;
            return Reader_1.Reader.apply(readF);
        };
    }
}
exports.Context = Context;
