"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.focus = void 0;
const Pack_1 = require("../Pack");
const FOCUS = 'FOCUS';
function focus(prev, path) {
    const focus0 = (0, Pack_1.flatMapValuePack)((v, k, seqId, deleted, noEffect) => path in v && !deleted ? (0, Pack_1.fullPack)(k, v[path], seqId, deleted, noEffect) : (0, Pack_1.emptyPack)());
    return {
        type: FOCUS,
        state: prefixKey => prev.state(prefixKey).map(focus0),
        keys: prefixKey => prev.keys(prefixKey),
        value: prefixKey => keys => prev.value(prefixKey)(keys).map(focus0)
    };
}
exports.focus = focus;
