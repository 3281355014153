import { IonApp, IonRouterOutlet, IonText, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { Navigate, BrowserRouter, Route, Routes } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';


/* Theme variables */
import "swiper/css";
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/swiper-bundle.css';

import './theme/variables.css';


import GoogleAuthPage from './pages/GoogleAuthPage';
import Room from './pages/Room';
import Home from './pages/Home';
import Login from './pages/Login';
import { mainStore } from './features/todo-matrix/service';


setupIonicReact({ mode: 'ios' });
mainStore

const App: React.FC = () => (
  <IonApp>
    <BrowserRouter >
      <Routes>
        <Route path="/oauth/google" element={<GoogleAuthPage />} />
        <Route path="/rooms/:roomId" element={<Room />} />
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </BrowserRouter>
  </IonApp>
);

export default App;
